import { useState, useEffect } from "react";

export const openInNewTab = url => {
	const newWindow = window.open(url, "_blank", "noopener, noreferrer");
	if (newWindow) newWindow.opener = null;
};

export function useDebounce(value, delay) {
	const [debouncedValue, setDebouncedValue] = useState(value);
	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);
		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);
	return debouncedValue;
}

export const convertBytes = bytes => {
	const units = ["b", "kb", "mb", "gb", "tb"];

	let i = 0;

	for (i; bytes >= 1000 && i < 4; i++) {
		bytes /= 1000;
	}

	return `${bytes.toFixed(2)} ${units[i]}`;
};

export const skinString = string => {
	const noSpecial = `${string.replace(/[^\w\s]/gi, "")}`;
	return noSpecial.replace(/\s/g, "_").toLowerCase();
};

export const getColors = (quantity, type) => {
	const newArr = [];

	const arrayOfColors = [
		"rgba(255,99,132,0.7)",
		"rgba(75,192,192,0.7)",
		"rgba(255,205,86,0.7)",
		"rgba(255,146,37,0.7)",
		"rgba(54,162,235,0.7)",
		"rgba(153,102,255,0.7)",
		"rgba(46,199,192,0.7)",
		"rgba(255,51,51,0.7)",
		"rgba(193,170,160,0.7)",
		"rgba(227,187,225,0.7)",
		"rgba(154,255,153,0.7)",
	];
	const arrayOfColorsHover = [
		"rgba(255,99,132,1)",
		"rgba(75,192,192,1)",
		"rgba(255,205,86,1)",
		"rgba(255,146,37,1)",
		"rgba(54,162,235,1)",
		"rgba(153,102,255,1)",
		"rgba(46,199,192,1)",
		"rgba(255,51,51,1)",
		"rgba(193,170,160,1)",
		"rgba(227,187,225,1)",
		"rgba(154,255,153,1)",
	];

	for (let i = 0; i < quantity; i++) {
		if (type === "hover") {
			newArr.push(arrayOfColorsHover[i] !== undefined ? arrayOfColorsHover[i] : randomRGB("hover"));
		} else {
			newArr.push(arrayOfColors[i] !== undefined ? arrayOfColors[i] : randomRGB());
		}
	}

	return newArr;
};

const randomRGB = type => {
	const o = Math.round;
	const r = Math.random;
	const s = 255;
	if (type === "hover") {
		return `rgba(${o(r() * s)},${o(r() * s)},${o(r() * s)},0.7)`;
	}
	return `rgba(${o(r() * s)},${o(r() * s)},${o(r() * s)},1)`;
};

export const generateProjectURL = (name, company) => {
	if (!name || !company) return "";

	const nameRemoveSpecial = `${name.replace(/[^\w\s]/gi, "")}`;
	const companyRemoveSpecial = `${company.replace(/[^\w\s]/gi, "")}`;
	const result = `${nameRemoveSpecial.replace(/\s/g, "_").toLowerCase()}_${companyRemoveSpecial
		.replace(/\s/g, "_")
		.toLowerCase()}`;
	return {
		client: `${process.env.REACT_APP_CLIENT_PROJECTS}?name=${result}`,
		server: `${process.env.REACT_APP_SERVER_URL}/project/${result}/index.htm`,
	};
};

export const copyToClipboard = (url, showSnackbar) => {
	navigator.clipboard.writeText(url).then(
		() => {
			showSnackbar({ message: "URL copiado", variant: "default", persist: false, autoHideDuration: 2000 });
		},
		err => {
			showSnackbar({
				message: "Não foi possivel copiar o URL",
				variant: "error",
				persist: false,
				autoHideDuration: 2000,
			});
			console.log("Error copying to clipboard ", err);
		},
	);
};

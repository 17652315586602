import React, { useState, useEffect, useRef } from "react";
import Button from "../../Buttons/Button";
import DialogPrefab from "../DialogPrefab";
import { TextField } from "../../Inputs/TextField/TextField";
import { createEntityWithFile, getEntity } from "../../../API/Requests";
import useGlobalSnackbar from "../../../Hooks/useGlobalSnackbar";
import { Controller, useForm } from "react-hook-form";
import { CompaniesAutoComplete } from "../../Inputs/AutoComplete/CustomAutocomplete";
import ProjectFilesDropzone from "../../Inputs/Dropzone/ProjectFilesDropzone";
import CustomSwitch from "../../Inputs/CustomSwitch";
import CustomTooltip from "../../Misc/CustomTooltip";
import { copyToClipboard, generateProjectURL } from "../../../utils";
import { Checkbox, FormControlLabel } from "@mui/material";

import { QRCode } from "react-qrcode-logo";
// import logo from "../../../Assets/Logos/fauna.svg";

import "../GenericCRUDDialog.css";

function AddProject(props) {
	const [loading, setLoading] = useState(false);
	const [companies, setCompanies] = useState([]);
	const [files, setFiles] = useState(null);
	const [progress, setProgress] = useState(0);
	const canvasRef = useRef(null);

	const { showSnackbar } = useGlobalSnackbar();
	const {
		control,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();

	const projectName = watch("name");
	const projectCompany = watch("company");

	useEffect(() => {
		if (props.open) {
			getEntity("companies").then(
				res => {
					setCompanies(res.data.plainData);
				},
				err => {
					console.log(err);
				},
			);
		}
	}, [props.open]);

	const dropzoneOptions = {
		options: {
			multiple: true,
		},
		text: {
			title: "Clique aqui para carregar os ficheiros.",
		},
	};

	const getQRcodeFile = () => {
		return new Promise((resolve, reject) => {
			if (canvasRef.current) {
				canvasRef.current.canvas.current.toBlob(blob => {
					resolve(new File([blob], "qrcode.jpg", { type: "image/jpg" }));
				});
			} else {
				reject(null);
			}
		});
	};

	const submitToServer = async data => {
		const formData = new FormData();
		const qrCodeFile = await getQRcodeFile();

		if (!files) {
			showSnackbar({
				message: "Falta fazer o upload dos ficheiros do projeto",
				variant: "info",
			});
			return;
		}

		formData.set("name", data.name);
		formData.set("companyId", data.company.id);
		formData.set("companyName", data.company.name);
		formData.set("online", data.online);
		formData.set("sendEmail", data.sendEmail);
		data.analyticsCode && formData.set("analyticsCode", data.analyticsCode);
		formData.append("files", files);
		formData.append("qrcode", qrCodeFile);

		const config = {
			onUploadProgress: progressEvent => {
				setProgress(Math.floor((progressEvent.loaded / progressEvent.total) * 100));
			},
		};

		setLoading(true);

		createEntityWithFile("projects", formData, config).then(
			res => {
				props.close();
				props.refresh();
				showSnackbar({ message: res.data });
				setLoading(false);
			},
			err => {
				console.log(err);
				showSnackbar({
					message: "Ocorreu um erro ao tentar criar o projeto",
					description: err.response,
					variant: "error",
				});
				setLoading(false);
			},
		);
	};

	return (
		<DialogPrefab open={props.open} close={props.close} maxWidth="xs" title="Adicionar novo projeto">
			<div className="add-dialog-div">
				<p className="add-dialog-title">Utilize o formulário a baixo para adicionar um novo projeto</p>
				<form className="add-dialog-user-fields" onSubmit={handleSubmit(submitToServer)}>
					<Controller
						control={control}
						name="name"
						defaultValue=""
						rules={{
							required: "Campo Obrigatório",
						}}
						render={({ field: { value, onChange } }) => (
							<TextField
								disabled={loading}
								label="Nome*"
								name="name"
								onChange={onChange}
								value={value}
								error={errors.name}
								helperText={errors.name?.message}
							/>
						)}
					/>
					<Controller
						control={control}
						name="company"
						defaultValue={null}
						rules={{
							required: "Escolha uma empresa para este projeto",
						}}
						render={({ field: { value, onChange } }) => (
							<CompaniesAutoComplete
								disabled={loading}
								id="company"
								label="Empresa*"
								name="company"
								data={companies}
								value={value}
								onChange={val => {
									onChange(val);
								}}
								error={errors.company ? true : false}
								helperText={errors.company?.message}
							/>
						)}
					/>
					<div style={{ display: "flex", gap: "20px" }}>
						<Controller
							control={control}
							name="analyticsCode"
							defaultValue=""
							render={({ field: { value, onChange } }) => (
								<TextField
									disabled={loading}
									label="Código Analytics"
									name="analyticsCode"
									onChange={onChange}
									value={value}
									error={errors.analyticsCode}
									helperText={errors.analyticsCode?.message}
								/>
							)}
						/>
						<Controller
							control={control}
							name="online"
							defaultValue={true}
							render={({ field: { value, onChange } }) => (
								<CustomSwitch
									disabled={loading}
									label="Online?"
									name="online"
									onChange={onChange}
									checked={value}
								/>
							)}
						/>
					</div>
					{loading ? (
						<>
							<div className="progress-bar">
								<div className="inner-progress-bar" style={{ width: `${progress}%` }}>
									<p style={{ marginRight: "5px" }}>{progress}%</p>
								</div>
							</div>
							<p style={{ fontSize: "12px", textAlign: "center" }}>
								A fazer o upload dos ficheiros por favor aguarde...
							</p>
						</>
					) : (
						<>
							{projectName && projectCompany ? (
								<div className="add-dialog-url-preview">
									<p style={{ color: "var(--secondary)" }}>URL para o 3dVista</p>
									<CustomTooltip title="Copiar URL">
										<p
											style={{ wordBreak: "break-all" }}
											onClick={() =>
												copyToClipboard(
													generateProjectURL(projectName, projectCompany?.name).server,
													showSnackbar,
												)
											}
										>
											{generateProjectURL(projectName, projectCompany?.name).server}
										</p>
									</CustomTooltip>
								</div>
							) : (
								<p
									style={{
										fontSize: 12,
										textAlign: "center",
										marginBottom: 10,
										color: "var(--secondary)",
									}}
								>
									Preencha os campos nome e empresa para ver o URL
								</p>
							)}
							<ProjectFilesDropzone
								setFiles={files => setFiles(files)}
								files={files}
								dropzoneOptions={dropzoneOptions}
							/>
							<FormControlLabel
								label="Notificar clientes por email?"
								control={
									<Controller
										control={control}
										name="sendEmail"
										defaultValue={true}
										render={({ field: { value, onChange } }) => (
											<Checkbox onChange={onChange} checked={value} />
										)}
									/>
								}
							/>
							<Button style={{ marginTop: "20px" }} loading={loading} type="submit">
								Criar Projeto
							</Button>
							<div style={{ display: "none" }}>
								<QRCode
									ref={canvasRef}
									value={generateProjectURL(projectName, projectCompany?.name).server}
									size={200}
									// logoImage={logo}
									// removeQrCodeBehindLogo={true}
									// logoWidth={65}
									// logoHeight={15}
									qrStyle="dots"
									eyeRadius={5}
									ecLevel="Q"
								/>
							</div>
						</>
					)}
				</form>
			</div>
		</DialogPrefab>
	);
}

export default AddProject;
